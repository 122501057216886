<template>
    <section id="hero">
        <v-img
            :min-height="minHeight"
            :src="require('@/assets/home-hero.jpeg')"
            class="white--text"
            gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
        >
            <v-container class="fill-height px-4 py-12">
                <v-responsive
                    class="d-flex align-center"
                    height="100%"
                    max-width="700"
                    width="100%"
                >
                    <base-heading title="Sylvaleo Global Limited" />

                    <base-body
                        >Sylvaleo global Limited is an indigenous company which
                        specialize in the sales and supplies of Food chemicals,
                        Industrial chemicals, solvents and other allied
                        chemicals which is applicable to a wide range industry.
                        Our organisation is managed by people of integrity and
                        uncommon poise to serve all our customers with absolute
                        satisfaction by offering unmatched quality goods with
                        the best prices.</base-body
                    >

                    <div
                        :class="
                            $vuetify.breakpoint.smAndDown
                                ? 'flex-column align-start'
                                : 'align-center'
                        "
                        class="d-flex flex-wrap"
                    >
                        <base-btn to="/about">Discover More</base-btn>

                        <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

                        <base-btn
                            :ripple="false"
                            class="pa-1"
                            to="/signup"
                            height="auto"
                            text
                            >Get Started Now</base-btn
                        >
                    </div>
                </v-responsive>
            </v-container>
        </v-img>
    </section>
</template>

<script>
export default {
    name: 'SectionHero',

    provide: {
        theme: { isDark: true },
    },

    computed: {
        minHeight() {
            const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh';

            return `calc(${height} - ${this.$vuetify.application.top}px)`;
        },
    },
};
</script>
